import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, forkJoin } from 'rxjs';
import { ComplianceCheckerService } from './compliance-checker/compliance-checker.service';
import { GatewayAuthenticationService } from './gateway/authentication/gateway-authentication.service';
import { OrganizationType } from './globals-classes/OrganizationType';
import { GlobalsService } from './globals-services/globals.service';
import { LanguageService } from './language/language.service';
//import { MailboxService } from './notification-inbox/mailbox.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { UserRole } from './user-interface';
import { RouteNavigationService } from './globals-services/navigation-service';
import { OrganizationConst } from './globals-classes/OrganizationConst';
import { MailboxService } from './notification-inbox/messages-inbox/mailbox.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    //selectedLanugage;
    availableLanguages = [];
    userRole: UserRole;
    loading: boolean = false;

    unreadNotificationTotal: number;

    private _subscription: Subscription;
    // private stepAccounts = ['OEKO_TEX_BHIVE_ID', 'OEKO_TEX_LEAD_TESTEX_BHIVE_ID', 'OEKO_TEX_COUNTRY_TESTEX_MDG_BHIVE_ID', 'OEKO_TEX_COUNTRY_TESTEX_SE_BHIVE_ID', 'OEKO_TEX_COUNTRY_TESTEX_KL_BHIVE_ID', 'OEKO_TEX_COUNTRY_TESTEX_TP_BHIVE_ID', 'OEKO_TEX_COUNTRY_TESTEX_JAKARTA_BHIVE_ID', 'OEKO_TEX_COUNTRY_TESTEX_PT_BHIVE_ID', 'OEKO_TEX_COUNTRY_TESTEX_HK_BHIVE_ID', 'OEKO_TEX_COUNTRY_OETI_INDIA_BHIVE_ID', 'OEKO_TEX_COUNTRY_OETI_EGYPT_BHIVE_ID', 'OEKO_TEX_COUNTRY_OETI_TURKEY_BHIVE_ID', 'OEKO_TEX_COUNTRY_IFTH_FRANCE_BHIVE_ID', 'OEKO_TEX_LEAD_IFTH_BHIVE_ID', 'OEKO_TEX_LEAD_FILK_BHIVE_ID', 'OEKO_TEX_COUNTRY_TESTEX_BE_BHIVE_ID', 'OEKO_TEX_COUNTRY_TESTEX_SH_BHIVE_ID', 'OEKO_TEX_LEAD_OETI_BHIVE_ID']

    constructor(
        private gwAuthService: GatewayAuthenticationService,
        public globalsService: GlobalsService,
        //   private router: Router,
        //   private _fuseNavigationService: FuseNavigationService,
        private complianceChecker: ComplianceCheckerService,
        private translateService: TranslateService,
        public languageService: LanguageService,
        private routeNavigationService: RouteNavigationService,

        private dialog: MatDialog,
        private http: HttpClient,
        private _mailboxService: MailboxService
    ) {
        routeNavigationService.startSaveHistory()

        const isUnportectedUrl =
            this.globalsService.unprotectedURL.filter((url) =>
                location.pathname.indexOf(url)
            ).length > 0;


        this._subscription = this.globalsService.loaderStateUpdate.subscribe(value => {
            this.loading = value;
        })
    }

    ngOnDestroy(): void {

        this._subscription.unsubscribe();

    }

    ngOnInit() {
        this.globalsService.loadUserProfile$.subscribe(res => {
            if (res)
                this.loadUserProfile()
        })
        this.loadUserProfile();
        this.getZDHCSubscriptionStatus();

        // setTimeout(() => {
        //     if(this.globalsService.isLoggedIn){

        //         this.getTotalUnreadNotificationCount();

        //         this._mailboxService.unReadNotificationTotal$.next(this.unreadNotificationTotal )

        //      }
        //  }, 3000);


    }

    ngAfterViewInit() {

        setTimeout(() => {
            // If User Login, then update badge and notification
            if (this.globalsService.isLoggedIn) {
                this._mailboxService.getUnreadMessagesCount().subscribe(
                    response => {
                        this._mailboxService.unReadNotificationTotal$.next(response.totalUnreadMessages);
                        this._mailboxService.updateBadgeTitle('NOTIFICATION_BOX.OTHER', 'mainNavigation', response.totalUnreadMessages);
                    },
                    error => {
                    }
                )
            }
        }, 3000);
    }

    //  getTotalUnreadNotificationCount(){
    //     this._mailboxService.getUnreadNotificationCount()
    //     .pipe(
    //         retryWhen(error => error.pipe(
    //           delay(1000),
    //           take(2)
    //         ))
    //       )
    //     .subscribe(
    //         response=>{
    //             console.log("Calling  count badge", response);
    //             if(response.status==='SUCCESS'){
    //                 this.unreadNotificationTotal=response.totalUnreadNotification

    //                 this.unreadNotificationTotal == 0 ? this.unreadNotificationTotal=null : this.unreadNotificationTotal

    //                 this._mailboxService.unReadNotificationTotal$.next(this.unreadNotificationTotal)
    //             }else{
    //                 this.unreadNotificationTotal=null;
    //             }

    //         },
    //         error=>{
    //             this.unreadNotificationTotal=null;
    //         }
    //     )

    //  }


    loadUserProfile() {
        console.log('loading');

        const whoAmIObservable = this.globalsService.whoAmI();

        const getAllPartnersObservable = this.globalsService.getAllPartners();

        const allObservables = forkJoin([
            getAllPartnersObservable,
            whoAmIObservable,
        ]);

        allObservables.subscribe((responses) => {
            this.globalsService.brands = responses[0]; //partners

            // let kantoorPartnerDetails = this.globalsService.brands.find(
            //     (brand) => brand.id == 1373
            // );

            // if (kantoorPartnerDetails != null && kantoorPartnerDetails != undefined && kantoorPartnerDetails.sharedWith) {
            //     GlobalsService.isKontoorSupplier = true;
            // }

            //        console.log(responses[0]);
            this.globalsService.profile = responses[1];
            GlobalsService.isKontoorSupplier = this.globalsService.profile['supplierOfKontoor'];
            GlobalsService.iswalmartSupplier = this.globalsService.profile['supplierOfWalmart'];
            GlobalsService.isHAndMSupplier = this.globalsService.profile['supplierOfHAndM'];
            GlobalsService.isOttoSupplier = this.globalsService.profile['supplierOfOttoGroup'];
            GlobalsService.isKomarBrandSupplier = this.globalsService.profile['supplierOfKomarBrands'];

            GlobalsService.isMts = this.globalsService.profile['uniqueIdentifier'] === 'MTS_BHIVE_ID'
            GlobalsService.isSupplierOfAldiHofer = this.globalsService.profile['supplierOfAldiEinkauf'];
            GlobalsService.isSupplierOfAldiEinkauf = this.globalsService.profile['supplierOfAldiHofer'];
            GlobalsService.issupplierOfOTBAndOther = this.globalsService.profile['supplierOfOTBAndOther'];
            GlobalsService.isSupplierOfSdsScreeningBhive = this.globalsService.profile['supplierOfSdsScreeningBhive'];

            GlobalsService.isTheChildrenPlaceSupplier = this.globalsService.profile['supplierOfTheChildrenPlace'];
            GlobalsService.isCarterSupplier = this.globalsService.profile['supplierOfCarter'];
            if (this.globalsService.profile['uniqueIdentifier'] != null)
                GlobalsService.isOekotex = this.globalsService.profile['uniqueIdentifier'].startsWith('OEKO_TEX')
            else GlobalsService.isOekotex = false;
            if (this.globalsService.profile['uniqueIdentifier'] != null) {
                if (GlobalsService.isMts || this.globalsService.profile['uniqueIdentifier'] === 'VF_CORPORATION_BHIVE_ID'
                    || this.globalsService.profile['uniqueIdentifier'].includes('EURO_FINS_')
                    || this.globalsService.profile['uniqueIdentifier'].includes('RED_NICE_BHIVE_ID'))
                    GlobalsService.isVF = true
            }
            else GlobalsService.isVF = false;
            GlobalsService.isKontoor = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.KONTOOR;
            GlobalsService.isOtto = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.OTTO;
            GlobalsService.isAldi = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.ALDI
            GlobalsService.isHofer = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.HOFER
            GlobalsService.isNike = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.NIKE
            GlobalsService.isAldiSourcing = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.ALDI_SOURCING
            // GlobalsService.isAldiImporter = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.ALDI_IMPORTER
            GlobalsService.isCandA = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.CandA
            GlobalsService.isInditex = this.globalsService.profile['uniqueIdentifier']?.includes('INDITEX')
            GlobalsService.isBrandZdhcMember = this.globalsService.profile['brand_zdhc_member'];
            GlobalsService.isAldiImporterBrand = this.globalsService.profile['isAldiImporter'] || this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.ALDI_IMPORTER;
            GlobalsService.isTCP = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.TCP;
            GlobalsService.isRedNice = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.RED_NICE;
            GlobalsService.isMiles = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.MILES;
            GlobalsService.isHm = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.HM;
            GlobalsService.isOneJeanswearGroupSupplier = this.globalsService.profile['supplierOfOneJeansWear'];
            GlobalsService.issupplierOfOTBAndOther = this.globalsService.profile['supplierOfOTBAndOther'];
            GlobalsService.isDPPBrandSupplier = this.globalsService.profile['supplierOfDPPBrand'];
            GlobalsService.isDPPBrandSupplierChina = this.globalsService.profile['supplierOfDPPBrandChina'];


            GlobalsService.isDPPBrand = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.DPP_BRAND;
            GlobalsService.isDPPBrandChina = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.DPP_BRAND_CHINA;

            GlobalsService.isLindexBrand = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.LINDEX_BRAND
            GlobalsService.isDummy = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.DUMMY
            GlobalsService.isDummyBestsellerBrand = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.BESTSELLER_DPP_DUMMY_BRAND_BHIVE_ID
            GlobalsService.isSdsScreeningBrand = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.SDS_SCREENING_BRAND




            GlobalsService.issupplierOfOTBAndOther = this.globalsService.profile['supplierOfOTBAndOther'];

            this.userRole = {
                organizationType:
                    this.globalsService.profile['organization_type'],
                chemIq: this.globalsService.profile['chemIq'],
                uniqueIdentifier:
                    this.globalsService.profile['uniqueIdentifier'],
            };

            let uniqueIdentifier = this.globalsService.profile['uniqueIdentifier']
            if (uniqueIdentifier === 'WALMART_BHIVE_ID') {
                GlobalsService.isWalmartUser = true;
            } else if (uniqueIdentifier === 'BLUWIN_BHIVE_ID') {
                GlobalsService.isBluwinUser = true;
            } else if (uniqueIdentifier === 'TUV_BHIVE_ID') {
                GlobalsService.isTuvUser = true;
            }

            GlobalsService.isTakkoBrand = this.globalsService.profile['uniqueIdentifier'] === OrganizationConst.TAKKO_GLOBAL_BHIVE_ID


            let subscriptionTypeAdmin = this.globalsService.profile['subscriptionTypeAdmin'];
            let subscriptionType = this.globalsService.profile['subscriptionType'];

            if (subscriptionTypeAdmin != undefined && subscriptionTypeAdmin != null) {
                GlobalsService.subscriptionTypeAdmin = subscriptionTypeAdmin
            } else {
                //subscriptionTypeAdmin is null or undefined
                GlobalsService.subscriptionTypeAdmin = (subscriptionType == undefined || subscriptionType == null) ? 'Full Bhive' : subscriptionType
            }



            this.globalsService.userRoles.next(this.userRole);
            this.languageService.selectedLanguage =
                this.globalsService.profile['language']['id'];

            this.languageService.getAvailableLanguages.subscribe(
                (languages) => {
                    //            console.log(languages);
                    this.languageService.availableLanguages = languages;
                    this.globalsService.languageChangeSubject.next(
                        this.languageService.selectedLanguage
                    );
                }
            );

            this.translateService.use(
                this.globalsService.profile['language']['languageCode']
            );
            //        console.log(this.globalsService.profile);
            // wenn passwordReset

            this.complianceChecker.setConnectedEntities(
                this.globalsService.brands,
                this.globalsService.profile['id']
            );
            //this.complianceChecker.setConnectedEntities(this.globalsService.brands, this.globalsService.profile['organization_id']);

            this.complianceChecker.getCriterionNames();

            // if (this.authenticationService.authenticationState.jwtAuthenticationToken.roles.includes("WET_PROCESSING_UNIT")) {

            if (
                this.globalsService.profile['organization_type'] ==
                OrganizationType.SUPPLIER
            ) {
                //Supplier

                // console.log("Fetching Subscription and Key");
                this.gwAuthService.fetchGatewaySubscriptionStatus();

                this.gwAuthService.fetchGwK();
            }
        });


    }

    getZDHCSubscriptionStatus() {

        const data = { userId: this.globalsService.profile['id'] };

        this.http.post<any>(this.globalsService.bhiveIncheckServiceUrl + "/subscription/getStatus", data).subscribe((status) => {
            GlobalsService.zdhcGatewaySubscriptionStatus = (status['status'] === 2);
        }, (err) => {
            if (err['status'] == '404') {
                //if the user the not exist, then the gateway subscription is set to false.
                GlobalsService.zdhcGatewaySubscriptionStatus = false;
            }
            console.log(err);
        }
        );
    }

}
